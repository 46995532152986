<template>
  <div ref="quoteTemplate" class="px-6 px-md-6 py-6 py-md-12">
    <table id="header">
      <tr>
        <th id="header-col-1"></th>
        <th id="header-col-2"></th>
      </tr>
      <tr>
        <td id="logo-wrapper">
          <img
            :src="organizationLogo"
            alt="organization Logo"
            id="logo"
            v-if="organizationLogo"
          />
          <div class="my-logo" v-else>Your Logo</div>
        </td>
        <td id="document-type-wrapper">
          <p id="document-type">{{ documentTitle }}</p>
          <br />
          <b>From</b><br />
          <b>{{ company_from }}</b
          ><br />
          {{ address_from }}<br />
          {{ zip_from }}<br />
          {{ country_from }}<br />
          <!-- {{ sender_custom1 }}<br />
          {{ sender_custom2 }}<br />
          {{ sender_custom3 }} -->
        </td>
      </tr>
    </table>
    <br />
    <hr style="border-color: #f2f2f2" />
    <table id="receiver-details-wrapper">
      <tr>
        <th id="receiver-details-wrapper-col-1"></th>
        <th id="receiver-details-wrapper-col-2"></th>
      </tr>
      <tr>
        <td>
          <b>For:</b><br />
          <b>{{ company_to }}</b>
          <br />
          {{ address_to }}<br />
          {{ zip_to }}, {{ city_to }}<br />
          {{ country_to }} <br />
          <b style="padding-right: 4px">Attention:</b>{{ client_custom1 }}<br />
        </td>
        <td id="details-value-wrapper">
          <b>RFQ/ {{ quotenumber }}</b
          ><br />
          <b style="padding-right: 4px">Date</b>{{ date }}<br />
        </td>
      </tr>
    </table>
    <br />
    <table id="products-table">
      <thead>
        <tr>
          <th id="products-table-col-1"></th>
          <th id="products-table-col-2"></th>
          <th id="products-table-col-3"></th>
          <th id="products-table-col-4"></th>
          <th id="products-table-col-5"></th>
          <th id="products-table-col-6"></th>
          <th id="products-table-col-7"></th>
        </tr>

        <tr>
          <td style="padding-left: 5px"><b>Items</b></td>
          <td align="left"><b>Material</b></td>
          <td align="left"><b>Description</b></td>
          <td style=""><b>QTY</b></td>
          <td style="padding-left: 5px"><b>UOM</b></td>
          <td style="padding-left: 10px">
            <b
              >Unit Price (<span>{{ currencySym || "NGN" }}</span
              >)</b
            >
          </td>
          <td style="padding-left: 5px; padding-right: 5px">
            <b
              >Total Price (<span>{{ currencySym || "NGN" }}</span
              >)</b
            >
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <hr />
          </td>
        </tr>
      </thead>
      <tbody v-for="(line, index) in lines" :key="index">
        <tr>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px">
            {{ index + 1 }}
          </td>
          <td style="vertical-align: top; padding-top: 8px" align="left">
            {{ line.material_no || "" }}
          </td>
          <td style="vertical-align: top; padding-top: 8px" align="center">
            {{ line.description }}
          </td>
          <td style="vertical-align: top; padding-top: 8px" align="center">
            {{ line.quantity }}
          </td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px">
            {{ line.UOM }}
          </td>
          <td
            style="
              padding-left: 5px;
              vertical-align: top;
              padding-top: 8px;
              padding-right: 8px;
            "
            align="right"
          >
            {{ line.price }}
          </td>
          <td
            style="
              padding-left: 5px;
              vertical-align: top;
              padding-top: 8px;
              padding-right: 8px;
            "
            align="right"
          >
            {{ line.total }}
          </td>
        </tr>
      </tbody>
      <!-- <tbody>
        <tr>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;">1</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;" align="center">2683078</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;">IATA Export Cans, 25 lts, G2&G3 Tri-Sure
            Steel. Closures weight 3.1kg. Height
            460mm</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;" align="center">40</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;">Each</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px; padding-right: 8px;" align="right">103.42
          </td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px; padding-right: 8px;" align="right">
            4,136.92
          </td>
        </tr>
        <tr>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;">2</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;" align="center">2683078</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;">IATA Export Cans, 25 lts, G2&G3 Tri-Sure
            Steel. Closures weight 3.1kg. Height
            460mm</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;" align="center">40</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px;">Each</td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px; padding-right: 8px;" align="right">103.42
          </td>
          <td style="padding-left: 5px; vertical-align: top; padding-top: 8px; padding-right: 8px;" align="right">
            4,136.92
          </td>
        </tr>
      </tbody> -->
      <tfoot>
        <tr>
          <td colspan="7">
            <hr />
          </td>
        </tr>
      </tfoot>
    </table>
    <br />
    <table id="total-wrapper">
      <tr>
        <th id="total-wrapper-col-1"></th>
        <th id="total-wrapper-col-2"></th>
      </tr>
      <tr>
        <td>
          <div class="totalCard">
            <small>Total amount</small><br />
            <b style="">{{ total | currency({ symbol: currencySym }) }}</b>
          </div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>
          <!-- <p class="text-alert mt-2">
            Lead time: 2-3 weeks DDP Port Harcourt
          </p> -->
          <p class="text-alert mt-2 mb-1">
            All prices {{ showTax ? "includes" : "excludes" }} VAT
          </p>
          <p class="text-alert mb-1" v-if="priceValidity">
            Price Validity: {{ priceValidity }} days
          </p>
          <p class="text-alert text-capitalize mb-1">
            Delivery terms: {{ deliveryterms }}
          </p>
          <p class="text-alert text-capitalize mb-1">
            Other terms: {{ otherTerms }}
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "quoteTemplate",
  props: {
    documentTitle: {
      type: String,
      default: "Quote",
    },
    company_from: {
      type: String,
      default: "",
    },
    address_from: {
      type: String,
      default: "",
    },
    zip_from: {
      type: [String, Number],
      default: " ",
    },
    city_from: {
      type: String,
      default: "",
    },
    country_from: {
      type: String,
      default: " ",
    },
    sender_custom1: {
      type: String,
      default: "",
    },
    sender_custom2: {
      type: String,
      default: "",
    },
    sender_custom3: {
      type: String,
      default: "",
    },
    company_to: {
      type: String,
      default: " ",
    },
    address_to: {
      type: String,
      default: " ",
    },
    zip_to: {
      type: [String, Number],
      default: " ",
    },
    city_to: {
      type: String,
      default: "",
    },
    country_to: {
      type: String,
      default: "",
    },
    client_custom1: {
      type: String,
      default: "",
    },
    client_custom2: {
      type: String,
      default: "",
    },
    client_custom3: {
      type: String,
      default: "",
    },
    quotenumber: {
      type: String,
      default: "",
    },
    date: {
      type: [String, Date],
      default: "",
    },
    dueDate: {
      type: String,
      default: "",
    },
    lines: {
      type: [Array, Object],
    },

    tax: {
      type: [String, Number],
      default: "0.00",
    },
    total: {
      type: String,
      default: "",
    },
    bankName: {
      type: String,
    },
    bankNumber: {
      type: String,
    },
    bankAccountName: {
      type: String,
    },
    bankSortCode: {
      type: String,
    },
    showTax: {
      type: Boolean,
      default: true,
    },
    addon: {
      type: Boolean,
      default: false,
    },
    organizationLogo: String,
    currencySym: String,
    se: String,
    gr: String,
    priceValidity: String,
    deliveryterms: String,
    otherTerms: String,
  },
  mixins: [currencyFilter],
};
</script>

<style lang="scss" scoped>
body {
  font-family: "Inter Tight";
  /*letter-spacing: 0.0mm !important;*/
  /*text-align: justify;*/
  /*font-kerning: auto;*/
  /*line-height: 80%;*/
}

table {
  width: 100%;
}

tr {
  line-height: 110%;
}

td {
  /*border: 1px black solid;*/
  font-size: 10px;
  /*x-small;*/
  color: #525e6e;
}

b {
  color: #19283d;
}

#header-col-1 {
  width: 39%;
}

#header-col-2 {
  width: 61%;
}

/*#header-col-3 {*/
/*    width: 44%;*/
/*}*/

#logo-wrapper {
  vertical-align: top;
  text-align: left;
}

#logo {
  max-width: 121px;
  max-height: 100px;
}

#document-type-wrapper {
  text-align: right;
  line-height: 1.2;
  vertical-align: top;
}

#document-type {
  font-size: 20px;
  font-weight: bold;
  color: #19283d;
  text-transform: capitalize;
}

.total {
  font-size: 16px;
  font-weight: bold;
}

#receiver-details-wrapper td {
  vertical-align: top;
}

#receiver-details-wrapper td:nth-child(2) {
  text-align: right;
}

#receiver-details-wrapper-col-1 {
  width: 50%;
}

#receiver-details-wrapper-col-2 {
  width: 35%;
}

#receiver-details-wrapper-col-3 {
  width: 15%;
}

#products-table {
  border-collapse: collapse;
}

#products-table-col-1 {
  width: 7%;
}

#products-table-col-2 {
  width: 13%;
}

#products-table-col-3 {
  width: 30%;
}

#products-table-col-4 {
  width: 7%;
}

#products-table-col-5 {
  width: 7%;
}

#products-table-col-6 {
  width: 17%;
}

#products-table-col-7 {
  width: 17%;
}

#products-table thead {
  background-color: #f6f7f9;
}

#products-table thead td:nth-child(2) {
  //text-align: center;
  // padding: 3px 0;
  vertical-align: top;
}

#products-table thead td:nth-child(3),
#products-table thead td:nth-child(4) {
  text-align: left;
  // padding: 3px 0;
}

#products-table tbody td:nth-child(3),
#products-table tbody td:nth-child(4) {
  text-align: left;
  padding: 3px 0;
  // padding-right: 5px;
}
//
#total-wrapper-col-1 {
  width: 50%;
}

#total-wrapper-col-2 {
  width: 30%;
}

#total-wrapper-col-3 {
  width: 20%;
}

#total-wrapper td {
  text-align: right;
}

#bottom-notice-wrapper-col-1 {
  width: 45%;
}

#bottom-notice-wrapper tbody tr td {
  text-align: left;

  #details-value-wrapper {
    min-width: 300px;
  }
}

.my-logo {
  width: 73px;
  height: 73px;
  background-color: #19283d;
  border-radius: 50%;
  text-align: center;
  /* Center content horizontally */
  color: #fff;
  font-family: "Inter";
  font-size: 12.218px;
  font-style: normal;
  font-weight: 700;
  line-height: 73px;
  /* Vertically center content */
}

.totalCard {
  width: 60%;
  height: auto;
  background-color: rgba(246, 247, 249, 1);
  border-radius: 4px;
  text-align: left;
  color: #19283d;
  font-family: "Inter Tight";
  padding: 16px;
}

.totalCard small {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  color: rgba(127, 145, 155, 1);
}

.totalCard b {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Inter";
}

.text-alert {
  color: rgba(255, 106, 106, 1);
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: "Inter";
  text-align: left;
}
</style>
