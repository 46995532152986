// download pdf mixins
export default {
  methods: {
    async handlePDFDownload(entityHypnID, uri, type, send) {
      const payload = {
        id: entityHypnID,
        send: send,
      };

      const reqPayload = {
        organization_hypnID: this.$orgHypnId,
        payload: payload,
      };
      //const reqPayload
      try {
        const res = await this.$store.dispatch(uri, reqPayload);

        if (process.env.NODE_ENV === "development") {
          console.log(res);
        }

        this.showToast({
          sclass: "success",
          show: true,
          message: res.message || type + " generated successfully",
          timeout: 3000,
        });

        // open a new window tab with res.data.file as the url
        window.open(res.data.file, "_blank");
      } catch (error) {
        console.error(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "Error Generating " + type,
          timeout: 3000,
        });
      }
    },
  },
};
